import React from 'react'
import { graphql } from 'gatsby'

import DocumentEducationList from '../components/documentEducationList'

const Edukacja = ({ data }) => {
  const educationItems = data.allSanityEducation.nodes

  return (
    <DocumentEducationList
      title="Edukacja"
      heading="Edukacja w Kinie Grażyna"
      items={educationItems}
    />
  )
}

export const query = graphql`
  {
    allSanityEducation(sort: { fields: priorityOnList, order: DESC }) {
      nodes {
        id
        title
        slug {
          current
        }
        description
        iconImage {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default Edukacja
